// Here you can add other styles
.ck-editor__editable {
    min-height: 300px;
}

.sun-editor .se-wrapper {
    min-height: 150px;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    height: 0px !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 7px 14px !important;
}


body {
    font-family: 'League Spartan', sans-serif !important;
    padding: 0px!important;
}

/* Admin css start now */
.box-outer {
background-color: #fff;
background-image: none;
border-radius: 10px;
box-shadow: 0 2px 10px 0 rgb(76 78 100 / 22%);
color: rgba(76,78,100,.87);
margin-bottom: 15px;
overflow: hidden;
transition: box-shadow .3s cubic-bezier(.4,0,.2,1) 0ms;
padding: 15px;
}
.box-outer h3 {
font-size: 16px;
margin: 0;
text-transform: uppercase;
}
.box-outer h4 {
font-size: 25px;
}
.box-outer a {
color: rgba(76,78,100,.87);
font-size: 14px;
text-decoration: underline;
}
.box-outer a:hover {
color: #351b00;
text-decoration: none;
}
.icon-outer {
align-items: center;
background: rgba(10,179,156,.18);
border-radius: 5px;
display: flex;
height: 50px;
justify-content: center;
padding: 5px;
width: 50px;
}
.box-outer a {
color: rgba(76,78,100,.87);
font-size: 14px;
text-decoration: underline;
}
.icon-outer svg {
height: 20px;
width: 20px;
}
.icon-outer svg path {
fill: #0ab39c;
}
.services-outer .icon-outer {
background: rgba(41,156,219,.18)!important;
}
.services-outer .icon-outer svg path {
    fill: #299cdb;
}
.categories-outer .icon-outer {
    background: rgba(247,184,75,.18)!important;
}
.categories-outer .icon-outer svg path {
    fill: #f7b84b;
}
.location-outer .icon-outer {
    background: rgb(77 49 30 / 21%) !important;
}
.location-outer .icon-outer svg path {
    fill: #4d311e;
}

li.nav-group>ul.nav-group-items li a {
    padding-left: 105px !important;
    position: relative;
}

li.nav-group>ul.nav-group-items li a svg.nav-icon {
    position: absolute;
    left: 130px;
}



.sidebar {
    background: #2f3a4b;
}

/* update by(12/06/2019) */
::-webkit-scrollbar{ width: 5px; height: 5px; -webkit-box-shadow: inset 0px 0px 6px rgba(0,0,0,0); box-shadow: inset 0px 0px 6px rgba(0,0,0,0); -webkit-border-radius: 5px; border-radius: 5px;}
/*add a shadow to the scrollbar here*/
::-webkit-scrollbar-track{ -webkit-box-shadow: inset 0px 0px 0px rgba(0,0,0,0); box-shadow: inset 0px 0px 0px rgba(0,0,0,0);  -webkit-border-radius: 5px; border-radius: 5px;}
/*this is the little scrolly dealio in the bar*/ 
::-webkit-scrollbar-thumb{ border-radius: 5px; background-color: rgba(0,0,0,0.2); height: 3px;}
/*nobody needs this little scrollbar corner, I mean really, get rid of it haha*/  
::-webkit-scrollbar-corner{ display: none; height: 0px; width: 0px;}
.avatar-img {
    width: 100%;
    height: auto;
    border-radius: 4px;
    background: #fff;
    padding: 0px 3px;
    margin-right: 13px;
}
.breadcrumb-item a {
    color: #4d311e;
}
.footer a {
    color: #4d311e;
}

button a {
    text-decoration: none !important;
    color: #000015 !important;
}
.MuiTablePagination-selectLabel {
    margin-bottom: 5px;
}

.MuiTablePagination-displayedRows {
    margin-bottom: 0px;
}

.bg-transparent .table-bordered.table {
    margin-bottom: 0px;
}

.input-group.mb-4 {
    position: relative;
}
.input-group.mb-4 input.form-control+div+svg,
.input-group.mb-4 input.form-control+div+span svg {
    position: absolute;
    right: 8px;
    top: 7px;
    z-index: 10;
    cursor: pointer;
}
.password-section {
    position: relative;
}
.password-section input.form-control+div+span svg{
    position: absolute;
    right: 18px;
    top: 8px;
    z-index: 10;
    cursor: pointer;
}

.was-validated input[type="password"]:invalid, .form-control.is-invalid,
.was-validated input[type="password"]:valid, .form-control.is-valid{
    background-image: none !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.loader-outer {
    background: rgb(3 28 49 / 66%);
    position: fixed;
    z-index: 10000;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: none;
    align-items: center;
    justify-content: center;
  }
  
  .loader-outer img {
    width: 100px;
  }
  
  .loader-outer.addloader {
    display: flex;
  }

  .invalid-server-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #e55353;
}
.avatar-md {
    width: 11rem !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused, .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
    background-color: #fff;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 7.5px 14px !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 14px !important;
    top: -9px !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    top:-2px !important;
}
.datepickerOuter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper.css-1yllih9-MuiPaper-root-MuiPickersPopper-paper>div>div:first-child {
    display: none;
}
.table > thead {
    background: #f7f7f7 !important;
}
.css-10hburv-MuiTypography-root, 
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root, 
.css-fa7jbv-MuiButtonBase-root-MuiChip-root,
.css-9e5uuu-MuiPaper-root-MuiAutocomplete-paper
{
    font-family: "League Spartan", sans-serif !important;
}
